export default {
  common: {
    network_error: "Network error",
    warning: "warning",
    need_reconnect_wallet: "Please reconnect your wallet.",
    web3_not_available: "Web3 is not available. Please check your connection.",
    copied_success: "Successfully copied to clipboard",
    copied_failed: "An error occurred copying to Clipboard",
    home: "Home",
    news: "News",
    navigation: "Navigation",
    marketplace: "Marketplace",
    user_center: "User Center",
    wallet: "Wallet",
    create: "Create",
    buy_currency: "Buy Crypto Currency",
    copy_address: "Copy Address",
    sign_out: "Sign Out",
    save_success: "Successfully saved",
    date_saving: "Saving date",
    img_format_error: "Image format is invalid",
    img_large_error: "The image cannot be larger than {0}MB",
    check_in_failed: "An error occurred checking in. Please try again later.",
    already_check_in: "You have already checked in today.",
    check_in_success: "Successfully checked in",
    img_upload_error:
      "An error occurred uploading the image. Please try again later.",
    follow_success: "Successfully followed",
    un_follow_success: "Successfully unFollowed",
    reminder: "Important Reminder",
    reminder_content:
      "The amount of BNB in your current account is less than <span class='color-word'>0.01BNB</span>, which may prevent you from completing this operation. Please add BNB to your wallet address as soon as possible. ",
    reminder_how: "How to get BNB?",
    reminder_get: "Get BNB",
    reminder_cont: "Continue",
  },
  home: {
    bjx_buy_success: "Successfully bought {count} BJXStar use {amount} {coin}",
    hot_news: "Hot news",
    featured: "Featured",
    creator: "Creator",
    user: "User",
    navigation_items: "Creation",
    all_nft: "All NFTs",
    mbd_price: "MBD Price",
    market_cap: "Market Cap",
    nft_volume: "NFT Volume",
    transfers: "Transfers",
    weekly_active_users: "Weekly Active Users",
    mbd_circulation: "MBD Circulation",
    "24h_nft_volume": "24h NFT Volume",
    "24h_transfers": "24h Transfers",
    "24h_nfts": "24h NFTs",
    latest_news: "Latest News",
    featured_prompts_nft: "Featured Prompts NFT",
    read_more: "Read more",
    featured_digital_arts_nft: "Featured Digital Arts NFT",
    activity: "Activity",
    bjx_pre_sale: "BJXStar NFT early bird pre-sale activity is in progress",
    bjx_what_value: "What is the value of BJXStar NFT?",
    price: "Price",
    buy_quantity: "Quantity to buy",
    after_early_bird:
      "After the early bird event ends, the price of Mint will return to the normal selling price of",
    buy: "BUY",
    comparison: "Comparison",
    what_is_douji: "What is DOUJI.AI？",
    comparison_tip:
      "DOUJI.AI is an AIGC&Web3 digital content creator economic ecosystem that reshapes digital content production and distribution methods and revenue distribution rules for global content creators. With the support of artificial intelligence and blockchain technology, it makes content creation easier and more collaborative. Creation is more convenient, content copyright transactions are safer and faster, and profits are more lasting.",
    feature: "Feature",
    web2_creator_platform: "Web2 Creator economic platform",
    regular_web3_platform: "Regular Web3 creator economic platform",
    douji_ai: "DOUJI.AI",
    tab_creation_threshold: "Creation Threshold",
    tab_low: "Low",
    tab_higher: "Higher",
    tab_data_ship: "Content Data Ownership",
    tab_none: "None",
    tab_owned: "Owned",
    tab_user_data_ownership: "User Data Ownership",
    tab_owned_customizable: "Owned and Customizable",
    tab_revenue_rights: "Revenue Distribution Rights",
    tab_none_distribution: "None,Depend on platform distribution",
    tab_owned_controlled: "Owned, Planned and Controlled",
    tab_revenue_model: "Revenue Model",
    tab_mainly_sponsorship: "Mainly Advertising Sponsorship",
    tab_limited: "Limited",
    tab_co_creation: "Content Co-Creation",
    tab_uncontrollable: "Uncontrollable",
    tab_content_sharing: "Content Value Sharing",
    tab_dao_gov: "DAO Governance",
    tab_rich_resources: "Rich production tools and resources",
    tab_creators_rights: "Creators have full rights",
    tab_content_model: "Content NFT DAO Governance Model",
    tab_diversified_models: "Diversified Revenue Models",
    tab_income_timely: "Income is controllable and settlement is timely",
    tab_platform_model: "Platform DAO governance model",
    tab_ai_tools: "Al-assisted Production Tools",
    tab_the_platform_standards:
      "The platform continues to introduce rich text.picture, audio, and video AGC tools to assist a large base of interested users to complete content production and creation more efficiently and quickly transform them into new creators, and improve the creative standards.",
    tab_content_resources: "Content Production Resources",
    tab_the_platform_production:
      "The platform provides an Al Prompt copyright trading market which collects a large number of Promo resources provided by professional Al developers making it convenient for internet users to create AlGC through Prompt, greatly reducing the threshold for content production and production.",
    tab_content_ownership: "Content Data Ownership",
    tab_content_circulation:
      "The content exists independently of the platform and is not subject to strong constraints and restrictions by the platform. The works are based on standardized NFT token issuance standards and can achieve cross-platform circulation.",
    tab_user_ownership: "User data ownership",
    tab_the_result_platform:
      "The result data of the interaction between creators and fans will become an integral part of the value of the creator's works and wil not be distributed and distributed centrally by the platform.",
    tab_income_ownership: "Income Distribution Ownership",
    tab_the_creator_creator:
      "The creator income model into determined by the platform, nor does it depend on advertisers or sponsors and can be diversified and defined by themselves. And the amount of income can be planned and controlled by the creator.",
    tab_the_platform_works:
      "The platform helps creators form an NFT DAO governance community centered on their works",
    tab_co_creation2: "Co-creation",
    tab_creator_value:
      "Creators can incorporate their fans or other creators into the closedNFT DAO governance model loop of content production through theallowina them to become co.creators of the work and cobenehciaries of future value.",
    tab_content_governance: "Content revenue and distribution governance",
    tab_creators_future_value:
      "Creators can incorporate their fans or other creators into the close coop of content production through the NFT DAO governance modelllowing them to become co creators of the work and co.beneficiaries of future value.",
    tab_platform_works:
      "The platform helps creators form an NFT DAO governance community centered on their works",
    tab_income_content: "Income from reading content",
    tab_users_based_on:
      "Users who browse the reading platform content will be able to obtain corresponding reading benefits based on",
    tab_content_income: "Content value-added income",
    tab_the_creator_held:
      "The creator's content will increase in value in the future as the browsing time and fan interaction data increase so that the creator can obtain premium income from the copyright NFT held.",
    tab_content_copyright_income: "Content copyright distribution income",
    tab_creators_transaction:
      "Creators can issue NFT copyright certificates base on a fixed total amount of content, sell NFTcopyrights through the primary market, and obtain90% of the revenue based on the actual transaction",
    tab_as_long_dao:
      "As long as the NFT copyright of the content is tradecon the secondary market, the creator and other NFT DAO members can obtain corresponding income according to the proportion set by the NFT DAO.",
    tab_based_income:
      "Based on the open and transparent algorithm of smart contracts, creators can fairly participate in the process of obtaining income, and there is no problem of platform black box operation and kol running on creative income.",
    tab_amount_controllable: "The amount of income is controllable",
    tab_creator_creative_income:
      "The creator's income from creation is only related to the increment a value of his own work data. Creators only need to focus their time and energy on creating more and better works and communicating with fans. They do not need to worry about the impact of platform traffic and other factors on creative income.",
    tab_Revenues_time: "Revenues can be settled in real time",
    tab_income_transfer:
      "The income eamed by the creator is automatically calculated by the smart contract. The creator can settle the income at any time asneeded. After the settlement is completed, the income will bedirectly credited to the account without the need for approval from others or transfer.",
    tab_douji_platform:
      "DOUJI.Al itself is a DAO. All participating roles in the ecosystem can obtain the governance rights and dividend rights of the platform DAO by staking the equity token MS of the platform.",
    tab_platform_rights: "Platform DAO governance rights",
    tab_dao_voting:
      "DAO members will have the power to optimize and adjust the parameters of future token issuance and revenue distribution rules of smart contracts by initiating proposal bidding and voting.",
    tab_platform_rights2: "Platform dividend rights",
    tab_dao_members:
      "DAO members automatically become de factor shareholders of the platform, and every income of the platform will be closely related to the income of eachDAO member.",
    video: "Video",
    how_use: "How to use?",
  },
  footer: {
    ai_ecology: "AIGC & Web3 Digital Content Creator Economic Ecology",
    video: "Video",
    about: "About",
    advertise: "Advertise",
    creator_policies: "Creator Editorial Policies",
    report_content: "Report Content",
    freelance_contributors: "Freelance Contributors",
    legal: "Legal",
    about_us: "About us",
    follow_us: "Follow us",
    market: "MarketPlace",
    explore: "Explore",
    create: "Create NFT",
    article: "Article NFTs",
    digital: "Digital Art NFTs",
    disclaimer: "Disclaimer",
    terms: "Terms of Service",
    privacy: "Privacy Policy",
    creator: "Creator Editorial Policies",
    white_paper: "WhitePaper",
    faq: "FAQ",
    logo: "LOGO",
    contact: "Contact",
    change: "ChangeLog",
    blog: "BLOG",
  },
  create: {
    category_required: "Category is required",
    maxSupply_required: "Nft max supply is required",
    maxSupply_invalid: "Nft max supply must be a number",
    availableSupply_required: "Nft available supply is required",
    availableSupply_invalid: "Nft available supply must be a number",
    availableSupply_more_than_available:
      "Nft available supply must be less than or equal current available supply",
    initialQuantity_required: "Initial quantity is required",
    initialQuantity_invalid: "Initial quantity must be a number",
    initialQuantity_more_than:
      "Initial quantity must be less than or equal to maxSupply*20%",
    initialPrice_required: "Initial price is required",
    initialPrice_invalid: "Initial price must be a number",
    title_required: "Title is required",
    description_required: "Description is required",
    image_required: "Cover image is required",
    keyword_required: "At least one keyword is required.",
    content_pub_required: "Open article content is required",
    save_uri_failed:
      "An error occurred saving the URI. Please check your connection.",
    nft_mint_failed:
      "An error occurred minting the NFT. Please check your connection.",
    no_permission_update: "You do not have permission to update this NFT.",
    data_not_modified: "The data you are trying to update is not modified.",
    nft_voting: "Cannot update a voting NFT.",
    step_1: "Step1",
    step_2: "Step2",
    step_3: "Step3",
    set_information: "Set NFT Mint information",
    write_content: "Write Content",
    mint_nft: "Mint NFT",
    create: "Create",
    create_your_collection: "Create Your Content DOUJI NFT Collection",
    the_soon: "The rest will be available soon",
    how_create: "How to Create?",
    update_mold_1: "Step 1 Update NFT Information",
    create_mold_1: "Step 1 Set NFT Mint Information",
    type: "Type",
    category: "Category",
    not_allow_change: "Not allowed to change after mint.",
    platform: "Platform",
    language: "Language",
    defines_level: "Defines the rarity level-",
    legendary: "Legendary(1),Epic(1+),Common(1000+)",
    available_supply: "NFT Available Supply",
    max_available: "NFT Maximum Available Supply Quantity",
    initial_quantity: "NFT Initial Mint Quantity",
    max_initial: "NFT Maximum Initial Mint Quantity",
    mint_price: "NFT Initial Mint Price",
    allowed_content:
      "Allowed to change after mint.Price set to 0,anyone can mint for free,access protected content.",
    back: "Back",
    update: "Update",
    save: "Save",
    next: "Next",
    update_mold_2: "Step 2 Update Content",
    create_mold_2: "Step 2 Write Content",
    title: "Title",
    name: "Name of your content name",
    description: "Description",
    write_detail_content: "Write some details about your content",
    cover_img: "Cover Image",
    upload_img_limit: "Upload image,support png,gif,jpg,jpeg,webp files",
    tag: "Tags",
    add: "Add",
    write_content_tip: "Write some keywords about your content",
    article_content: "Article content",
    open_access: "Open to Access",
    protected: "Protected",
    step3_mint: "Step 3 Mint NFT",
    attributes: "DOUJI NFT Attributes",
    content_type: "Content Type",
    max_supply: "Max Supply",
    avail_supply: "Available Supply",
    initial_mint_qu: "Initial Mint Quantity",
    primary_market: "Primary Market",
    available: "Available",
    mint: "Mint",
    congratulations: "Congratulations",
    minted_success: "Your content NFT has been minted successfully!",
    tx_id: "Transaction ID",
    token_address: "Token Address",
    token_id: "Token ID",
    view_nft: "View NFT",
    set_dao: "Set NFT DAO Governance",
    dao_earn: "NFT DAO Earnings",
    dao_member_receive: "The NFT DAO members of this item will receive",
    for_sale: "for every sale",
    execution_vote: "NFT modify execution threshold",
    "execution_vote-count": "NFT modify execution mVoteCount",
    modification_value:
      "Modification requests can only be executed when all voting weights of the NFT DAO are greater than or equal to the value.",
    cancel: "Cancel",
    apply: "Apply",
    set_promotion: "Set NFT Sales Promotion",
    allow_nfts:
      "Allows licensed tokens to be purchased at discounted prices for NFTs",
    licensed_info: "Licensed tokens information",
    bsc_standard: "BSC Chain token standard",
    bsc_contract: "BSC Chain token smart contract address",
    allow_support: "Allows token smart contracts that support",
    bep_standards: "BEP-20, BEP-721, and BEP-1155 standards",
    how_many_token:
      "How many discounts can a user get by holding one Licensed token?",
    discounts_nft: "Discounts for purchasing NFTs",
    update_success:
      "You have successfully initiated a vote to amend the NFT profile!",
  },
  news: {
    home: "Home",
    news: "News",
  },
  "news-detail": {
    have_no_nft: "You do not have any this NFTs.",
    unlock_success: "Unlock success.",
    unlock_failed: "Unlock failed.",
    mbd_approve_failed:
      "An error occurred approving the MBD. Please check your wallet.",
    nft_mint_failed:
      "An error occurred minting the NFT. Please check your connection.",
    nft_mint_success: "NFT minted successfully.",
    order_create_failed: "Order create failed.",
    order_create_success: "Order create success.",
    order_price_required: "Order price is required.",
    order_quantity_required: "Order quantity is required.",
    order_price_invalid: "Order price is invalid.",
    order_quantity_invalid: "Order quantity is invalid.",
    stake_count_required: "Stake count is required.",
    stake_count_invalid: "Stake count is invalid.",
    stake_success: "Stake success.",
    stake_failed: "Stake failed.",
    retrieve_count_required: "Retrieve count is required.",
    retrieve_count_invalid: "Retrieve count is invalid.",
    retrieve_success: "Retrieve success.",
    retrieve_failed: "Retrieve failed.",
    retrieve_unable: "The retrieve condition is not met.",
    get_blind_box_failed:
      "An error occurred getting the blind box. Please try again later.",
    submit_success: "Submit success.",
    update_unable: "No permission to update.",
    order_cancel_success: "Order cancel success.",
    swap_success: "Swap success.",
    vote_success: "Vote successfully.",
    execute_success: "Execute successfully.",
    cancel_success: "Cancel vote successfully",
    vote_already: "You have already voted.",
    like_success: "Thank you for liking it",
    collect_success: "Collect successfully.",
    receive_box: "Receive Blind Box",
    receive: "Receive",
    open_box: "Open Blind Box",
    open_rewards:
      "Open the blind box and you will 100% get the following rewards",
    open: "Open",
    give_up: "Give Up",
    open_fee: "Open Blind Box Fee:",
    daily_check_gift: "Daily Check-in Gift",
    get_id: "Get it",
    congratulations: "Congratulations",
    gift_account:
      "Your gift will be transferred to your pending settlement account",
    list_item: "List Your Item",
    sale_price: "NFT Sale Price",
    min_price_tip:
      "The minimum selling price of NFT must be greater than 0 MBD",
    item_quantity: "Item Quantity",
    max_quantity: "Maximum NFT sale quantity",
    create_order: "Create Order",
    secondary_market: "Secondary Market",
    from: "From",
    price: "Price(MBD)",
    available: "Available",
    buy: "Buy",
    cancel: "Cancel",
    attributes: "DOUJI NFT Attributes",
    content_type: "Content Type",
    category: "Category",
    platform: "Platform",
    language: "Language",
    max_supply: "Max Supply",
    available_supply: "Available Supply",
    curr_supply: "Current Supply",
    subscribe: "Subscribe",
    un_subscribe: "Unsubscribe",
    dao_governance: "DOUJI NFT DAO Governance",
    dao_earn: "NFT DAO Earnings",
    dao_share_tx:
      "The amount of share NFT DAO receives for each NFT transaction",
    dao_gov: "NFT DAO Governance",
    dao_threshold: "Execution Threshold",
    dao_dividend_pool: "NFT Staker Bonus Dividend Pool",
    dao_balance: "Balance",
    dao_all_staked: "All members NFT Staked",
    dao_you_staked: "You NFT Staked",
    dao_retrieve_num: "Retrieve BSC Block Number",
    dao_curr_num: "Current BSC Block Number",
    dao_stake: "Stake",
    dao_retrieve: "Retrieve",
    dao_proposal: "NFT DAO Proposal",
    dao_proposal_initiated:
      "has initiated a proposal modify the content and rules of this NFT at",
    dao_proposal_review: "Please review the proposal before",
    dao_proposal_invalidated:
      "It expires. After that, the proposal will be invalidated.",
    dao_view_latest: "View the latest NFT information >>",
    dao_you_threshold: "Threshold",
    dao_approve: "Approve",
    dao_execute: "Execute",
    dao_cancel: "Cancel",
    dao_voting: "Your Voting",
    inf_title: "DOUJI NFT Information",
    inf_address: "Token Address",
    inf_token: "Token ID",
    inf_standard: "Token Standard",
    inf_creator: "Creator",
    inf_create_time: "Created At",
    inf_update_time: "Updated At",
    pri_title: "Primary Market",
    pri_ava: "Available",
    pri_own: "You owned",
    pri_ori: "Original Price",
    pri_mint: "Mint",
    pri_owning: "Owning",
    pri_to_get: "Token(s) To Get",
    pri_dis: "discount",
    pri_contract: "Token Contract",
    pri_token_id: "TokenId",
    pri_token_own: "Token owned",
    pri_unused: "unused",
    latest_title: "View the latest NFT information",
    latest_open: "Open to Access",
    latest_pro: "Protected",
    latest_least: "Owning At Least 1 DOUJ NFT (",
    latest_id: "Token ID",
    latest_click: "And Clicking",
    latest_unlock: "Unlock",
    latest_tag: "Tags",
    latest_close: "Close",
    rew_title: "Reading Reward",
    rew_time: "Total reading time today",
    rew_mission: "Receive blind box mission today",
    rew_income: "Estimated reading income today",
    rew_settle: "Settlement",
    ret_title: "Retrieve Your staked NFTs",
    ret_desc:
      "Retrieving staked NFTs will not affect the cumulative staking time.",
    ret_quantity: "Retrieve Staked NFT Quantity",
    ret_tip: "Maximum Retrieve Staked NFT quantity",
    ret_btn: "Retrieve",
    revision_hist: "Revision history",
    stk_title: "Stake Your NFTs",
    stk_desc:
      "By staking your NFT, you can settle the NFT DAO dividend bonus once a week.Each time you stake NFT, your staking time will restart.NFTs that have been staked cannot be sold, but protected content can continue to be unlockedThe number of NFTs that have been staked is the number of your voting rights.",
    stk_quantity: "Stake NFT Quantity",
    stk_tip: "Maximum NFT Stake quantity",
    stk_btn: "Stake",
    home: "Home",
    news: "News",
    dao_update_att: "Update NFT Attributes",
    dao_update_con: "Update Content",
    dao_sale_pro: "Set NFT Sales Promotion",
    dao_set_gov: "Set NFT DAO Governance",
    last_edit: "Last Edited",
    view_hist: "View History",
    latest_tran: "Latest Transactions",
    tx_id: "Transactions ID",
    to: "To",
    amount: "Amount",
    no_tx: "No transactions",
    more_creator: "More from this creator",
    no_more_data: "No more data",
  },
  marketplace: {
    home: "Home",
    marketplace: "Marketplace",
    all_nft: "ALL DOJI NFTs",
    result: "Result",
    all_types: "ALL Types",
    all_category: "ALL Category",
    all_platform: "ALL Platform",
    most_viewed: "Most Viewed",
    search_tip: "Search by name or Token ID",
    sort_late_time: "Late creation time",
    sort_early_time: "Early creation time",
    sort_high_price: "High price",
    sort_low_price: "Low price",
  },
  user: {
    search_tip: "Search by name or Token ID",
    menu_balance: "Balance",
    menu_nfts: "NFTs",
    menu_profile: "Profile",
    settlement: "Settlement",
    cancel: "Cancel",
    profile_save_success: "Profile saved successfully.",
    settle_success: "Settlement application submitted successfully.",
    settle_success_1:
      "Settlement successful! The settled assets have been transferred to your wallet.",
    inc_d_title: "NFT Stake income settlement",
    plat_d_title: "Platform Rewards Settlement",
    plat_d_desc:
      "The fee of <span class='color-word'>{0} MBD</span> will be deducted from this settlement, and the MBD and BJXStar after settlement will be transferred to your wallet address within 24 hours. The first settlement will receive BNB rewards worth <span class='color-word'>$0.30 USD</span>.",
    balance: "Balance",
    ba_dist: "Distributes income to be settled",
    ba_income: "Creation Income Settlement",
    ba_st_in: "NFT Stake income settlement",
    ba_go_sett: "Go Settlement",
    ba_plat_rew: "Platform rewards to be settled",
    ba_sett_fee: "Settlement Fee",
    my_nft: "My DOUJI NFTs",
    items: "Items",
    profile: "Profile",
    co_im: "Cover Image",
    alias: "Alias",
    alias_tip: "Alias to be displayed on the public profile page",
    short_desc: "Short Description",
    short_tip: "Short bio to be displayed on the public profile page",
    social: "Social accounts",
    loc: "Location",
    plan_success: "Join in success",
    st_token_id: "Token ID",
    st_name: "NFT name",
    st_pool_balance: "Bonus Dividend Pool Balance(MBD)",
    st_rate: "Your NFTs / Members NFTs",
    st_income: "Stake NFT Income(MBD)",
    ct_title: "Creation Income Settlement",
    ct_pool_balance: "Creator Fund Pool Balance(MBD)",
    ct_rate: "Token Value / Total Value",
    ct_income: "NFT DAO Income(MBD)",
    plan_title: "Creator Creation Income Plan",
    plan_desc_1:
      "Creators, NFT holders, and pledgers can all participate in the settlement of income from creators' works at any time, and all income after settlement will be transferred to the NFT DAO equity dividend pool.",
    plan_desc_2:
      "Once the number of followers of the creator reaches 100, and the total reading time of the creation reaches 100 hours, he can join the creator's creation income plan income will be obtained based on the incremental value of reading time, likes, collections, etc. in the future, and the income from the creation will belong to the work. Allowed by DAO.",
    plan_sub: "Subscriber",
    plan_h: "Hour",
    plan_time: "Total Creation Reading Time",
    plan_join: "Join The Plan",
  },
  creator: {
    subscribe: "Subscribe",
    collect: "Collected",
  },
};
